<template>
    <v-container class="full-height">
        <v-col class="full-height">
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
            <template v-else-if="metaCampaignDefinition">
                <DataStudio
                    v-if="metaCampaignDefinition.type === 'datastudio'"
                    v-bind="metaCampaignDefinition.dataStudio"
                />
                <EmbeddedPage
                    v-if="metaCampaignDefinition.type === 'embed'"
                    v-bind="metaCampaignDefinition.embed"
                />
                <DynamicCampaign
                    v-else
                    :metaCampaignDefinition="metaCampaignDefinition"
                    :query="query"
                />
            </template>
            <v-overlay v-else :absolute="false">
                <JumpingDots />
            </v-overlay>
        </v-col>
    </v-container>
</template>

<script>
export default {
    components: {
        DynamicCampaign: () => import('@/components/DynamicCampaign'),
        DataStudio: () => import('@/components/DataStudio'),
        EmbeddedPage: () => import('@/components/EmbeddedPage'),
        JumpingDots: () => import('@/components/loaders/JumpingDots'),
    },
    props: {
        campaignId: {
            type: String,
            required: true,
        },
        query: {
            type: Object,
        },
    },
    data() {
        return {
            error: null,
            metaCampaign: null,
            values: {},
            locationWatchId: null,
            position: null,
        }
    },
    created() {
        this.setRouteTitle()
    },
    watch: {
        metaCampaignDefinition() {
            this.setRouteTitle()
        },
    },
    beforeUnmount() {
        if (this.locationWatchId) {
            // Clear the location watch, if there is one.
            navigator.geolocation.clearWatch(this.locationWatchId)
        }
    },
    methods: {
        setRouteTitle() {
            this.$route.meta.title = this.metaCampaignDefinition.title || ''
        },
    },
    computed: {
        metaCampaignDefinition() {
            const definitions = this.$store.getters[
                'organisation/getMetaCampaigns'
            ]
            return definitions.find(
                (definition) => definition.id === this.campaignId
            )
        },
    },
}
</script>

<style scoped>
div.full-height {
    height: 100%;
}
</style>
